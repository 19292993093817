import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  TextField,
} from "@mui/material"
import TitleBanner from "../TitleBanner/TitleBanner"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form"
import { object, string } from "yup"
import * as Yup from "yup"
import { useState } from "react"
import { generateAccessCode } from "../../utils/util"
import isEmpty from "lodash-es/isEmpty"
import { entityDTO } from "../../utils/Types/DTO"
import { registerEntity } from "../../API/services"
import { useNavigate } from "react-router-dom"
import { URLS } from "../../constants"

const AddEntity = () => {
  const navigate = useNavigate()
  const [accessCode] = useState(generateAccessCode())
  const validationSchema = object().shape({
    username: string().required("Email is required").email("Email is invalid"),
    confirm_username: Yup.string()
      .required("Confirm Email is required")
      .oneOf([Yup.ref("username"), ""], "Emails must match"),
    organizationName: string().required("Organization Name is required"),
    firstName: string().required("First Name is required"),
    lastName: string().required("Last Name is required"),
    phone: string().required(),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
  }
  const methods = useForm(formOptions)

  const { handleSubmit } = methods
  const formState = useFormState({
    control: methods.control,
  })

  const onFormSubmit = (data: FieldValues) => {
    if (
      !isEmpty(data.firstName) &&
      !isEmpty(data.lastName) &&
      !isEmpty(data.organizationName) &&
      !isEmpty(data.phone) &&
      !isEmpty(data.username)
    ) {
      const payload: entityDTO = {
        organizationName: data.organizationName,
        accessCode: accessCode,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.username,
        phone: data.phone,
      }
      submitData(payload)
    }
  }

  const submitData = (payload: entityDTO) => {
    registerEntity(payload)
      .then(async (response) => {
        if (response.data) {
          console.log(response.data)
          navigate("/org")
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Container component="main">
      <CssBaseline />
      <TitleBanner title={`Add Organization`} />
      <Paper elevation={3} style={{ padding: "20px", margin: "10px" }}>
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onFormSubmit)}
            sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="firstName"
                      type="text"
                      label="First Name"
                      name="firstName"
                      autoComplete="firstName"
                      required
                      autoFocus
                      error={!!formState.errors.firstName}
                      helperText={
                        formState.errors.firstName
                          ? formState.errors.firstName.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="lastName"
                      type="text"
                      label="Last Name"
                      name="lastName"
                      autoComplete="lastName"
                      required
                      autoFocus
                      error={!!formState.errors.lastName}
                      helperText={
                        formState.errors.lastName
                          ? formState.errors.lastName.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="username"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="username"
                      type="email"
                      label="Email Address"
                      name="username"
                      autoComplete="email"
                      required
                      autoFocus
                      error={!!formState.errors.username}
                      helperText={
                        formState.errors.username
                          ? formState.errors.username.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="confirm_username"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="confirm_username"
                      type="email"
                      label="Confirm Email Address"
                      name="confirm_username"
                      autoComplete="email"
                      required
                      autoFocus
                      error={!!formState.errors.confirm_username}
                      helperText={
                        formState.errors.confirm_username
                          ? formState.errors.confirm_username.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="organizationName"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="organizationName"
                      type="text"
                      label="Organization Name"
                      name="organizationName"
                      autoComplete="organizationName"
                      required
                      autoFocus
                      error={!!formState.errors.organizationName}
                      helperText={
                        formState.errors.organizationName
                          ? formState.errors.organizationName.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phone"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="phone"
                      type="text"
                      label="Phone"
                      name="phone"
                      autoComplete="phone"
                      autoFocus
                      error={!!formState.errors.phone}
                      helperText={
                        formState.errors.phone
                          ? formState.errors.phone.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Access Code"
                  disabled={true}
                  fullWidth
                  name="accessCode"
                  type="text"
                  id="accessCode"
                  value={accessCode}
                />
              </Grid>
            </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Add Organization
              </Button>
          </Box>
        </FormProvider>
      </Paper>
    </Container>
  )
}
export default AddEntity
