import { useState } from "react"
import { URLS } from "../constants"
import { useLocation } from "react-router-dom"
import { getESATReport } from "../API/services"
import { format, startOfYear } from "date-fns"
import { getErrorMessage } from "../utils/util"
import { Box, Button, Stack } from "@mui/material"
import { useSuperAdminStore } from "../store/SuperAdminStore"
import { useNotificationStore } from "../store/NotificationStore"
import OptionDropDown from "../components/OptionsList/optionsDropDown"
import { failed } from "../components/Notification/NotificationMessages"
import { SimpleCardWithTitleAndValue } from "../components/Assesment/components/SimpleCardWithTitleAndValue"

export const DashboardWithTwoCards = () => {
  const location = useLocation()

  const setNotification = useNotificationStore((state) => state.setNotification)
  const { setCardInfo, cardInfo, orgList, topSkills } = useSuperAdminStore(
    (state) => ({
      setCardInfo: state?.setCardInfo,
      cardInfo: state?.cardInfo,
      orgList: state?.orgList,
      topSkills: state?.topSkills,
    })
  )

  const orgId = location?.state?.orgId
  const selectedOrgName: string =
    orgList?.find((org: any) => org?.id == orgId)?.organizationName || ""
  const getTodayDate = new Date()
  const today = format(getTodayDate, "yyyy-MM-dd")
  const firstOfTheYear = format(startOfYear(getTodayDate), "yyyy-MM-dd")

  const [selectedOption, setSelectedOption] = useState<string>(
    selectedOrgName ?? ""
  )
  const selectedOrgID: any = orgId
    ? orgId
    : orgList?.find((item: any) => item?.organizationName == selectedOption)?.id

  const handleSelectOrg = (event: any) => {
    setSelectedOption(event?.target?.value)
  }

  const fetchESATReport = async () => {
    try {
      const res: any = await getESATReport(
        firstOfTheYear,
        today,
        selectedOrgID ? selectedOrgID : ""
      )
      if (res?.data) {
        setCardInfo({
          ...cardInfo,
          avgScore: {
            grow: res?.data?.AVERAGE_GROW_ASSESSMENT_SCORE || 0,
            screen: res?.data?.AVERAGE_SCREEN_ASSESSMENT_SCORE || 0,
          },
        })
      }
    } catch (error: any) {
      if (error.response) {
        setNotification(failed(getErrorMessage(error)))
      }
    }
  }

  return (
    <Stack
      sx={{
        gap: 5,
        height: "70vh",
        alignItems: "center",
        justifyContent: "center",
      }}>
      {!cardInfo?.isSoftSkillsCard && (
        <Stack sx={{ flexDirection: "row", gap: 2 }}>
          <OptionDropDown
            options={orgList}
            selectedOption={selectedOption}
            handleOnChange={handleSelectOrg}
            setSelectedOption={setSelectedOption}
            showSearchButton={false}
          />
          <Button variant="outlined" size="medium" onClick={fetchESATReport}>
            search
          </Button>
        </Stack>
      )}
      <Stack
        sx={{
          flexDirection: "row",
        }}>
        <Stack sx={{ gap: 4 }}>
          {cardInfo?.isSoftSkillsCard ? (
            <Stack flexDirection={"row"}>
              <SimpleCardWithTitleAndValue
                value={0}
                bgColor="#ADD8E6"
                isSoftSkillsDB={true}
                isSoftSkillCard={true}
                description={topSkills?.screen}
                title={`${cardInfo?.title} SCREEN`}
                url={`${URLS?.assessment?.screen_assessment_details}?assessment=ESAT™ Screen`}
                styles={{
                  minWidth: "210px",
                  maxWidth: "250px",
                  padding: "20px",
                }}
              />
              <SimpleCardWithTitleAndValue
                value={0}
                bgColor="#90EE90"
                isSoftSkillsDB={true}
                isSoftSkillCard={true}
                description={topSkills?.grow}
                title={`${cardInfo?.title} GROW`}
                url={`${URLS?.assessment?.screen_assessment_details}?assessment=ESAT™ Grow`}
                styles={{
                  minWidth: "210px",
                  maxWidth: "250px",
                  padding: "20px",
                }}
              />
            </Stack>
          ) : (
            <Stack flexDirection={"row"}>
              <Box
                onClick={() =>
                  setCardInfo({
                    title: "Average SCREEN Assessment Score",
                    score: cardInfo?.avgScore?.screen || 0,
                    bgColor: "#ADD8E6",
                    isSoftSkillsCard: false,
                  })
                }>
                <SimpleCardWithTitleAndValue
                  value={cardInfo?.avgScore?.screen}
                  bgColor="#ADD8E6"
                  url={`${URLS?.assessment?.avg_screen_assessment_score}?assessment=ESAT™ Screen`}
                  title={
                    <p>
                      Average <strong>SCREEN</strong> Assessment Score
                    </p>
                  }
                />
              </Box>
              <Box
                onClick={() =>
                  setCardInfo({
                    title: "Average GROW Assessment Score",
                    score: cardInfo?.avgScore?.grow || 0,
                    bgColor: "#90EE90",
                    isSoftSkillsCard: false,
                  })
                }>
                <SimpleCardWithTitleAndValue
                  value={cardInfo?.avgScore?.grow}
                  bgColor="#90EE90"
                  url={`${URLS?.assessment?.avg_grow_assessment_score}?assessment=ESAT™ Grow`}
                  title={
                    <p>
                      Average <strong>GROW</strong> Assessment Score
                    </p>
                  }
                />
              </Box>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
