import React from 'react';
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../utils/util';

const SubscriptionExpired: React.FC = () => {
//   const navigate = useNavigate();

  const handleLogout = () => {
    // navigate('/contact-support');
    logoutUser();
  };

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Paper elevation={3} sx={{ padding: 4, textAlign: 'center', borderRadius: 3, maxWidth: 400 }}>
        <Box mb={3}>
          <Typography variant="h5" component="h1" sx={{ fontWeight: 600 }}>
            Subscription Expired
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1.5, lineHeight: 1.6 }}>
            No Active Subscription Found. Please contact your organization’s administrator for more details.
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogout}
          sx={{ paddingX: 4, paddingY: 1.2, borderRadius: 2 }}
        >
          Logout
        </Button>
      </Paper>
    </Container>
  );
};

export default SubscriptionExpired;