import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container, CssBaseline, Paper, Grid, TextField, Button, Checkbox, FormControlLabel, MenuItem
} from '@mui/material';
import { IUserDTO, UserDetailsDTO } from '../../utils/Types/DTO';
import { getLoggedInUserDetails, updateUserDetails } from '../../API/services';
import TitleBanner from '../TitleBanner/TitleBanner';
import { capitalizer, getErrorMessage, permissionLabels, permissionsString } from '../../utils/util';
import { useNotificationStore } from '../../store/NotificationStore';
import { failed, success } from '../Notification/NotificationMessages';
import { isEmpty } from 'lodash-es';

// Placeholder for your API calls
const getSupervisorDetails = async (id:string) => {

    const response = await  getLoggedInUserDetails(id ?? '');
  // Implement fetching supervisor details
  return response.data; // Placeholder return
};

const updateSupervisor = async (id:string, data:IUserDTO) => {
  // Implement the update logic

const tempData:UserDetailsDTO = {
    id: id,
    firstName: data.firstName ?? '',
    lastName: data.lastName ?? '',
    email: data.email ?? '',
    phone: data.phone ?? '',
    country: data.country ?? '',
    state: data.state ?? '',
    role: data.role ?? '',
    permissions: data.permissions ?? '',
    fullName: data.fullName ?? '',
    yearOfBirth: data.yearOfBirth ?? '',
    province:data.province ??  '',
    manager: data.manager ?? '',
    tag: data.role ?? '',
};
console.log("Supervisor Details?: ",tempData);
 await updateUserDetails(tempData);
};

const UpdateSupervisor = () => {
  const navigate = useNavigate();
  let { supervisorId } = useParams<{ supervisorId: string }>();
  supervisorId = atob(supervisorId ?? "");
//   const { supervisorId } = useParams();
  const [formData, setFormData] = useState<IUserDTO>();
  const [permissions, setPermissions] = useState({});
  const [initialPermissions, setInitialPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [areCheckboxesDisabled, setAreCheckboxesDisabled] = useState(false);
  const setNotification = useNotificationStore((state) => state.setNotification);
  
  useEffect(() => {
    const fetchSupervisorDetails = async () => {
      setLoading(true);
      try {
        const response:IUserDTO = await getSupervisorDetails(supervisorId??'');
        const data = response; // Adjust based on your API response
        setFormData({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          country: data.country,
          state: data.state || data.province, // Adjust according to your data structure
          role: data.role,
          organizationMap: data.organizationMap,
          manager: data.manager,
          yearOfBirth: data.yearOfBirth,
          province: data.province,
          fullName: data.fullName,
        });

        // Convert permissions string to an object
        const permissionsObj = (data.permissions ?? '').split(',').reduce((acc, curr) => ({
            ...acc,
            [curr]: true,
        }), {});
        setPermissions(permissionsObj);
        setInitialPermissions(permissionsObj); // Store initial permissions
      } catch (error) {
        console.error("Failed to fetch supervisor details", error);
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    fetchSupervisorDetails();
  }, [supervisorId]);

const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
        ...prev,
        [name]: value,
    }));

    if (name === 'role') {
        if (value === 'ADMINISTRATOR') {
            const allPermissions = permissionsString.reduce((acc, permission) => ({
                ...acc,
                [permission]: true,
            }), {});
            setPermissions(allPermissions);
            setAreCheckboxesDisabled(true);
        } else {
            setPermissions(initialPermissions);
            setAreCheckboxesDisabled(false);
        }
    }
};

const handlePermissionChange = (permission: string) => {
    setPermissions((prev: {[key: string]: boolean}) => ({
            ...prev,
            [permission]: !prev[permission],
    }));
};

const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(isEmpty(Object.keys(permissions).filter((key: string) => permissions[key as keyof typeof permissions]).join(','))){
      setNotification(failed('Please select at least one permission'));
    }else{
      const updateData = {
        ...formData,
        permissions: Object.keys(permissions).filter((key: string) => permissions[key as keyof typeof permissions]).join(','),
    };
     
    console.log("Supervisor Details?: ",updateData);
      try {
        // await updateSupervisor(supervisorId ?? '', updateData);

        console.log("Supervisor Details?: ",updateData);

        setNotification(success('Supervisor updated successfully'));

        // navigate("/supervisors"); // Adjust this as per your routing
    } catch (error:any) {
        console.error("Failed to update supervisor", error);
        setNotification(failed(getErrorMessage(error)));
        // Handle error
    }
    }
};

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
    <TitleBanner title={"Update Supervisor"} />
      <Paper elevation={3} style={{ padding: '20px', margin: '10px' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Form Fields */}
            <TextFieldGridItem label="First Name" name="firstName" value={formData?.firstName ?? ''} onChange={handleFormChange} />
            <TextFieldGridItem label="Last Name" name="lastName" value={formData?.lastName ?? ''} onChange={handleFormChange} />
            <TextFieldGridItem label="Email" name="email" value={formData?.email??''} onChange={handleFormChange} type="email" disable={true} />
            <TextFieldGridItem label="Phone" name="phone" value={formData?.phone??''} onChange={handleFormChange} />
            <TextFieldGridItem label="Country" name="country" value={formData?.country??''} onChange={handleFormChange} />
            <TextFieldGridItem label="State/Province" name="state" value={formData?.state??''} onChange={handleFormChange} />
            <TextFieldGridItem label="Manager" name="manager" value={formData?.manager??''} onChange={handleFormChange} type="email" />

            <Grid direction='row' container item justifyContent="flex-start">
                        <h4 style={{ color: '#0088F2' }}>Role</h4>
                    </Grid>
            <TextFieldGridItem label="Role" name="role" value={formData?.role ?? ''} onChange={handleFormChange} select options={["ADMINISTRATOR", "SUPERVISOR", "MANAGER", "DIRECTOR"]} />

            <Grid direction='row' container item justifyContent="flex-start">
                        <h4 style={{ color: '#0088F2' }}>Permissions</h4>
                    </Grid>
            {/* Permissions */}
            {permissionsString.map((permission, index) => (
                <Grid item xs={12} sm={6} key={index}>
                    <FormControlLabel
                        control={<Checkbox checked={permissions[permission as keyof typeof permissions] || false} onChange={() => handlePermissionChange(permission)} disabled={areCheckboxesDisabled} />}
                        label={permissionLabels[permission] || capitalizer(permission)}
                    />
                </Grid>
            ))}

            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Update Supervisor
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

interface TextFieldGridItemProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    type?: string;
    select?: boolean;
    options?: string[];
    disable?: boolean;
    }
const TextFieldGridItem = ({ label, name, value, onChange, type = "text", select = false, options = [], disable=false }:TextFieldGridItemProps) => (
  <Grid item xs={12} sm={6}>
    <TextField
      fullWidth
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      required
      type={type}
      select={select}
      disabled={disable}
    >
      {select && options.map((option, index) => (
         <MenuItem key={index} value={option}>
         {option}
       </MenuItem>
      ))}
    </TextField>
  </Grid>
);

export default UpdateSupervisor;
