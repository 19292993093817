import { create } from 'zustand';
import { getLicenseAvailable } from "../API/services";
import { UserDetailsDTO, SubscriptionDTO } from "../utils/Types/DTO";

interface SubscriptionState {
  hasActiveSubscription: boolean;
  setActiveSubscription: (status: boolean) => void;
  checkSubscriptionStatus: (user: UserDetailsDTO) => Promise<void>;
}

export const useSubscriptionStore = create<SubscriptionState>((set) => ({
  hasActiveSubscription: true,

  setActiveSubscription: (status: boolean) => set({ hasActiveSubscription: status }),

  checkSubscriptionStatus: async (user: UserDetailsDTO) => {
    console.log("Checking subscription status...");
    const organizationIds = Object.keys(user.organizationMap);

    for (const orgId of organizationIds) {
      try {
        console.log(`Checking licenses for organization ID: ${orgId}`);
        const response = await getLicenseAvailable(orgId);
        const data = response.data;

        if (data.esatSubscriptions && data.esatSubscriptions.length > 0) {
          const activeSubscription = data.esatSubscriptions.some(
            (subscription: SubscriptionDTO) => subscription.status === 'active'
          );

          if (activeSubscription) {
            console.log("Active subscription found for organization:", orgId);
            set({ hasActiveSubscription: true });
            return;
          }
        }
      } catch (error) {
        console.error(`Error checking subscription for organization ID ${orgId}:`, error);
      }
    }

    console.log("No active subscriptions found");
    // Use user.organizationOwner instead of ACCOUNT_OWNER flag
    const isOrganizationOwner = user.organizationOwner === true || user.organizationOwner === 'true';
    set({ hasActiveSubscription: isOrganizationOwner });
  },
}));
