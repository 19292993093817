import React, { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { Card, Stack, Typography } from "@mui/material"
import { TitleValueStack } from "../../ComponentStyles/cardStyles"

interface SkillsCardType {
  title: string | ReactNode
  url?: string
  bgColor?: string
  growSkill: string
  screenSkill: string
  styles?: any
}

const SkillsCard = ({
  title,
  url,
  bgColor,
  growSkill,
  screenSkill,
  styles = {},
}: SkillsCardType) => {
  const navigate = useNavigate()
  return (
    <Card
      sx={{
        ...styles,
        m: 1,
        flexGrow: 1,
        boxShadow: 1,
        borderRadius: "22px",
        backgroundColor: bgColor,
      }}>
      <TitleValueStack onClick={() => url && navigate(url)}>
        <Typography sx={{ fontSize: "0.8rem" }}>{title}</Typography>
        <Stack>
          <Stack sx={{ alignItems: "center", flexDirection: "row" }}>
            <Typography sx={{ fontSize: "0.9rem" }}> SCREEN:</Typography>
            <Typography sx={{ fontSize: "0.7rem" }}>{screenSkill}</Typography>
          </Stack>
          <Stack
            sx={{
              alignItems: "center",
              flexDirection: "row",
            }}>
            <Typography sx={{ fontSize: "0.9rem" }}>GROW: </Typography>
            <Typography sx={{ fontSize: "0.7rem" }}>{growSkill}</Typography>
          </Stack>
        </Stack>
      </TitleValueStack>
    </Card>
  )
}

export default SkillsCard
