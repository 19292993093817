import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"

interface CardInfo {
  title: string
  score: number
  bgColor: string
  isSoftSkillsCard: boolean
  avgScore?: {
    grow: number
    screen: number
  }
}

export interface SuperAdminStoreState {
  orgList: any[]
  cardInfo: CardInfo
  topSkills: {
    grow: string
    screen: string
  }
  setOrgList: (list: any) => void
  setCardInfo: (info: CardInfo) => void
  setTopSoftSkills: (skill: any) => void
}

export const useSuperAdminStore = create(
  persist<SuperAdminStoreState>(
    (set) => ({
      orgList: [],
      cardInfo: {
        title: "",
        score: 0,
        bgColor: "",
        isSoftSkillsCard: false,
        avgScore: {
          grow: 0,
          screen: 0,
        },
      },
      topSkills: {
        grow: "",
        screen: "",
      },
      setCardInfo: (info: CardInfo) => set({ cardInfo: info }),
      setOrgList: (list: any) => set({ orgList: list }),
      setTopSoftSkills: (skill: any) => set({ topSkills: skill }),
    }),
    {
      name: "orgList",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
