import * as Yup from "yup"
import isEmpty from "lodash-es/isEmpty"
import { useLocation, useNavigate } from "react-router-dom"
import TitleBanner from "../TitleBanner/TitleBanner"
import { yupResolver } from "@hookform/resolvers/yup"
import { editEntity } from "../../API/services/UserServices"
import {
  useForm,
  Controller,
  useFormState,
  FormProvider,
} from "react-hook-form"
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  TextField,
} from "@mui/material"
import { getErrorMessage } from "../../utils/util"
import { failed } from "../Notification/NotificationMessages"
import { useNotificationStore } from "../../store/NotificationStore"

const EditOrganization = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const setNotification = useNotificationStore((state) => state.setNotification)
  const orgId = location?.state?.orgId;
  const orgName = location?.state?.orgName;
  const organizationPhone = location?.state?.organizationPhone;
  const organizationAddress = location?.state?.organizationAddress;
  const organizationMailingAddress = location?.state?.organizationMailingAddress;

  const validationSchema = Yup.object().shape({
    organizationName: Yup.string().required("Organization Name is required"),
    organizationPhone: Yup.string()
      .required("Phone number is required")
      .matches(/^\+?[0-9]{1,15}$/, "Invalid phone number format"),
    organizationAddress: Yup.string()
      .required("Address is required")
      .matches(
        /^[\w\s,.-]*$/,
        "Invalid address format (only alphanumeric, spaces, commas, or hyphens are allowed)"
      ),
      organizationMailingAddress: Yup.string()
      .required("Mailing Address is required")
      .matches(
        /^[\w\s,.-]*$/,
        "Invalid address format (only alphanumeric, spaces, commas, or hyphens are allowed)"
      ),
  })

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      organizationName: orgName,
      organizationPhone:organizationPhone,
      organizationAddress:organizationAddress,
      organizationMailingAddress:organizationMailingAddress
    },
  })

  const { handleSubmit, control } = methods
  const { errors } = useFormState({ control })

  const onFormSubmit = (data: any) => {
    if (
      !isEmpty(data.organizationPhone) &&
      !isEmpty(data.organizationAddress) &&
      !isEmpty(data.organizationName)&&
      !isEmpty(data.organizationMailingAddress)
    ) {
      const payload = {
        organizationId: orgId,
        organizationPhone: data.organizationPhone,
        organizationAddress: data.organizationAddress,
        organizationName: data.organizationName,
        organizationMailingAddress: data.organizationMailingAddress,
      }
      submitEdit(payload)
    }
  }

  const submitEdit = (payload: any) => {
    editEntity(payload)
      .then(async (response: any) => {
        if (response.data) {
          console.log(response.data)
          routeToOrg()
        }
      })
      .catch((error) => {
        setNotification(failed(getErrorMessage(error)))
        console.log(error)
      })
  }

  const routeToOrg = () => navigate("/org")

  return (
    <>
      <Container component="main">
        <CssBaseline />
        <TitleBanner title={"Edit Organization"} />
        <Paper elevation={3} style={{ padding: 15, margin: "10px 2px" }}>
          <FormProvider {...methods}>
            <Box
              component="form"
              onSubmit={handleSubmit(onFormSubmit)}
              noValidate
              sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                {/* //! Organization Name Field */}
                <Grid item xs={12}>
                  <Controller
                    name="organizationName"
                    control={control}
                    render={({ field }:any) => (
                      <TextField
                        {...field}
                        label="Organization Name"
                        placeholder="Enter Organization Name"
                        fullWidth
                        error={!!errors.organizationName}
                        helperText={
                          errors.organizationName
                            ? errors.organizationName.message
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                {/* //! Phone Number Field */}
                <Grid item xs={12}>
                  <Controller
                    name="organizationPhone"
                    control={control}
                    render={({ field }:any) => (
                      <TextField
                        {...field}
                        label="Phone"
                        placeholder="+1 (615) 555-1234  Extension (optional)"
                        fullWidth
                        inputProps={{
                          inputMode: "tel",
                          pattern: "[+0-9]*",
                        }}
                        onInput={(e: any) => {
                          e.target.value = e.target.value.replace(
                            /[^+0-9]/g,
                            ""
                          )
                        }}
                        error={!!errors.organizationPhone}
                        helperText={errors.organizationPhone ? errors.organizationPhone.message : ""}
                      />
                    )}
                  />
                </Grid>
                {/* //! Address Field */}
                <Grid item xs={12}>
                  <Controller
                    name="organizationAddress"
                    control={control}
                    render={({ field }:any) => (
                      <TextField
                        {...field}
                        label="Address"
                        placeholder="Address , City , State , Country , ZIP"
                        fullWidth
                        inputProps={{
                          onInput: (e: any) => {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9\s,.-]/g,
                              ""
                            )
                          },
                        }}
                        error={!!errors.organizationAddress}
                        helperText={
                          errors.organizationAddress ? errors.organizationAddress.message : ""
                        }
                      />
                    )}
                  />
                </Grid>
                {/* //! Mailing Address*/}
                <Grid item xs={12}>
                  <Controller
                    name="organizationMailingAddress"
                    control={control}
                    render={({ field }:any) => (
                      <TextField
                        {...field}
                        label="Mailing Address"
                        placeholder="Address , City , State , Country , ZIP"
                        fullWidth
                        inputProps={{
                          onInput: (e: any) => {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9\s,.-]/g,
                              ""
                            )
                          },
                        }}
                        error={!!errors.organizationMailingAddress}
                        helperText={
                          errors.organizationMailingAddress ? errors.organizationMailingAddress.message : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" fullWidth>
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Button
              fullWidth
              sx={{ mt: 2 }}
              variant="contained"
              onClick={routeToOrg}>
              Cancel
            </Button>
          </FormProvider>
        </Paper>
      </Container>
    </>
  )
}

export default EditOrganization
